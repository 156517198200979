<template>
    <div class="top-bar">
        <s-header :name="$t('LAN.robot_name')"></s-header>
    </div>
    
    <div class="robot">
        <div class="robot-box">
            <div class="robot-chat" id="robotChat">
                <div class="chat-list">
                    <div class="msg msg-time">{{ date }}</div>
                    <div class="msg msg-tips">{{ robotName }}</div>
                    <!-- 机器人回复 -->
                    <div class="msg msg-text-link" >
                        <p class="msg-avatar">
                            <img src="@/assets/helper/robot-avatar.png" alt="">
                        </p>
                        <div class="msg-content">
                            <div class="content-html">{{ welcomMsg }}</div>
                            <div class="content-link">
                                <p class="link-title">{{$t('LAN.question_common')}}:</p>
                                <p class="link-item" v-for="(ques, key) in welcomList" :key="key" @click="onClickSearch(ques.question)">{{ques.question}}</p>
                                <p class="link-switch" @click="onRandomQuestions">{{$t('LAN.change_batch')}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- 聊天 -->
                    <template v-for="(item, key) in chatList" :key="key">
                        <!-- 我的回复 -->
                        <div class="msg msg-text-link msg-right" v-if="item.type == 0" >
                            <div class="msg-content" >
                                <i class="content-loading" v-if="item.loading"></i>
                                <div class="content-html">{{item.msg}}</div>
                            </div>
                        </div>

                        <!-- 机器人回复 带点赞 -->
                        <div class="msg msg-text-link" v-if="item.type == 1" >
                            <p class="msg-avatar">
                                <img src="@/assets/helper/robot-avatar.png" alt="">
                            </p>
                            <div class="msg-content" >
                                <div class="content-html">{{item.msg}}</div>
                            </div>
                            <!-- <div class="msg-evaluate">
                                <a class="like"/>
                                <a class="no-like"/>
                            </div> -->
                        </div>

                        <!-- 机器人回复 带人工按钮 -->
                        <div class="msg msg-text-link" v-if="item.type == 2" >
                            <p class="msg-avatar">
                                <img src="@/assets/helper/robot-avatar.png" alt="">
                            </p>
                            <div class="msg-content">
                                <div class="content-html">{{item.msg}}</div>
                                <p class="link-item link-item-one" @click="goTo('feedbackLink')">{{$t('LAN.customer_service')}}</p>
                            </div>
                        </div>
                        
                        <!-- 机器人回复 模糊搜索问题 -->
                        <div class="msg msg-text-link" v-if="item.type == 3" >
                            <p class="msg-avatar">
                                <img src="@/assets/helper/robot-avatar.png" alt="">
                            </p>
                            <div class="msg-content">
                                <div class="content-html">{{item.msg}}</div>
                                <div class="content-link">
                                    <p href="javascript:;" class="link-item" v-for="(ques, qkey) in item.questions" :key="qkey" @click="onClickSearch(ques.question)">{{ ques.question }}</p>
                                </div>
                            </div>
                            <!-- <div class="msg-evaluate">
                                <a class="like"/>
                                <a class="no-like"/>
                            </div> -->
                        </div>

                    </template>

                </div>
            </div>

            <div class="robot-btn">
                <div class="btn" @click="goTo('feedbackLink')">{{$t('LAN.contact_customer')}}</div>
                <!-- <div class="btn" @click="goTo('feedbackLink')">{{$t('LAN.game_web')}}</div> -->
                <div class="btn" @click="goTo('forgotLink', {back:1})">{{$t('LAN.forget_pwd')}}</div>
            </div>

            <div class="srarch_main" v-show="srarchShow && searchList.length">
                <ul class="list">
                    <li v-for="(item, key) in searchList" :key="key" v-html="item.showQues" @mousedown="onClickSearch(item.question)" />
                </ul>
            </div>


            <div class="robot-control">
                <!-- <router-link class="nav-list-item" to="helper">
                    <van-icon class="icon" name="service-o" />
                    <span>{{$t('LAN.contact_customer')}}</span>
                </router-link> -->
                <input
                    v-model="message" 
                    type="text" 
                    :placeholder="$t('LAN.ques_placeholder')" 
                    autocomplete="off" 
                    class="control-input"
                    @input="onInput"
                    @focus="onFocus"
                    @blur="onBlur"
                    @clear="onClear">
                
                <div class="control-btn" @click="onSend">
                    <span>{{$t('LAN.send')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import sHeader from '@/components/Header'
import { Toast } from 'vant'
import { useRouter } from 'vue-router'
import { validateNull } from '@/utils/validate';
import { loadConfig, searchQuestions, randomQuestions, saveSearchHistory, saveRobotChatHistory, getRobotChatHistory } from '@/utils/questions'
import { useI18n } from 'vue-i18n'


export default {
    setup() {
        const router = useRouter()
        const { t } = useI18n()

        const state = reactive({
            searchList:[],
            srarchShow:false,
            date: "",
            robotName:t('LAN.robot_name2'),
            welcomMsg:t('LAN.welcom_msg'),
            welcomList:[],
            chatList:[
                // {type:0, msg:"战斗力怎么突然下降了？"},
                // {type:1, msg:"退出公会后公会技能会失效导致战斗力下降，需要重新加入公会激活公会技能加成~"},
                // {type:0, msg:"战斗力怎么突然下降了？"},
                // {type:3, msg:"您是想问:", questions:[{question:"问题"}]},
            ],
            message:"",
            questionsCfg:null,
        });

        onMounted(() => {
                   //删除指定元素，影响到事件监听
                   var myobj = document.getElementsByClassName("block")
            myobj[0].remove();
            Toast.loading(t('LAN.connection'));
            loadConfig(initData);
        });

        const initData = (questionCfg) => {
            state.questionsCfg = questionCfg;
            state.date = formatDate(new Date());
            onRandomQuestions();
            // 记录还原
            let historyList = getRobotChatHistory();
            for (let i = 0; i < historyList.length; i++) {
                let msg = historyList[i];
                createMsg(0, msg, [])
                onReply(msg);
            }

            Toast.clear()
        }

        const onSend = () => {
            let msg = state.message
            state.message = ""
            if (validateNull(msg)) {
                Toast(t('LAN.enter_question'))
                return;
            }
            saveRobotChatHistory(msg);
            saveSearchHistory(msg);
            createMsg(0, msg, [])

            setTimeout((function() {
                onReply(msg)
            } ), 1100);
        }

        const onReply = (word) => {
            if (validateNull(word)) {
                return;
            }
            let list = []
            let questionsCfg = state.questionsCfg
            if (questionsCfg) {
                for (const key in questionsCfg) {
                    for (const id in questionsCfg[key]) {
                        let item = questionsCfg[key][id];
                        if (item.question.indexOf(word) > -1) {
                            list.push(item)
                        }
                    }
                }
            }
            if (list.length > 0) {
                if (list.length > 1) {
                    createMsg(3, t('LAN.are_you_asking'), list)
                } else {
                    createMsg(1, list[0].answer, [])
                }
            } else {
                // 找不到
                createMsg(2, t('LAN.robot_not_found'), [])
            }
        }

        const createMsg = (type, msg, questions) => {
            state.chatList.push(
                {
                    type: type,
                    msg: msg,
                    questions: questions,
                    loading: true
                }
            )
            scrollToBottom(state.chatList.length - 1)
        }

        const scrollToBottom = (index) => {
            let t = document.getElementById("robotChat");
            setTimeout((function() {
                t.scrollTop = t.scrollHeight
            } ), 150);
            setTimeout((function() {
                state.chatList[index].loading = false;
            }), 1000)
        }

        // 获得输入框焦点
        const onFocus = () => {
            state.srarchShow = true;
            onInput();
        }

        const onBlur = () => {
            state.srarchShow = false;
        }
        
        const onInput = () => {
            state.searchList = searchQuestions(state.questionsCfg, state.message)
        }

        const onClickSearch = (val) => {
            state.message = val
            onSend()
        }

        const goTo = (name, params) => {
            router.push({ name: name, query: params })
        }

        const onRandomQuestions = () => {
            state.welcomList = randomQuestions(state.questionsCfg)
        }
        const formatDate = (time, format='YY-MM-DD hh:mm:ss')=>{
            var date = new Date(time);
            var year = date.getFullYear(),
            month = date.getMonth()+1,//月份是从0开始的
            day = date.getDate(),
            hour = date.getHours(),
            min = date.getMinutes(),
            sec = date.getSeconds();
            var preArr = Array.apply(null,Array(10)).map(function(elem, index) {
            return '0'+index;
        });
 
    var newTime = format.replace(/YY/g,year)
              .replace(/MM/g,preArr[month]||month)
              .replace(/DD/g,preArr[day]||day)
              .replace(/hh/g,preArr[hour]||hour)
              .replace(/mm/g,preArr[min]||min)
              .replace(/ss/g,preArr[sec]||sec);
 
    return newTime;
}
        return {
            ...toRefs(state),
            goTo,
            onSend,
            onFocus,
            onBlur,
            onInput,
            onClickSearch,
            onRandomQuestions,
        }
    },
    components: {
        sHeader,
    },
}
</script>


<style lang="less" scoped>
@import '../../../style/mixin';

// 消息
.msg {
    margin-bottom: 20px;
}

.msg-time {
    text-align: center;
    font-size: 13px;
    color: #b1b1b1;
}

.msg-time:first-child {
    margin-top: 2.667vw;
    margin-bottom: 2vw;
}

.msg-tips {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding: 0 4vw;
    background-color: #eee;
    border-radius: 5px;
    font-size: 13px;
    color: #b1b1b1;
}

.msg-right.msg-text-link {
    padding: 0;
    text-align: right;
    
    .msg-content {
        text-align: left;
        color: #666;
        background-color: #eee;
    }
}

.msg-text-link {
    position: relative;
    padding: 0 0 0 50px;
    text-align: left;
    line-height: 25px;
    font-size: 15px;
    color: #333;
    .msg-avatar {
        position: absolute;
        top: 0;
        left: 0;
        img {
            width: 35px;
            height: 35px;
            border-radius: 35px;
        }
    }

    .msg-content {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        max-width: 300px;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 10px;

        .link-title {
            margin: 20px 0 5px;
        }
        .link-item {
            color: #6e87ea;
            border-bottom: 1px solid #eee;
            padding-bottom: 0;
            border-bottom: none;
        }
        .link-switch {
            padding: 27px 0 0;
            text-align: center;
            color: #b1b1b1;
            background: url("../../../assets/feedback/link-switch.png") no-repeat;
            background-size: 30px 30px;
            background-position: 25% 25px;
        }

        .content-loading {
            position: absolute;
            top: 50%;
            left: -25px;
            margin-top: -8px;
            width: 20px;
            height: 20px;
            background: url("../../../assets/common/loading.png") no-repeat;
            background-size: 20px 20px;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
        }
    }

    .msg-evaluate {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        a {
            display: inline-block;
            width: 4.8vw;
            height: 4.8vw;
            margin-top: 2.667vw;
        }
        a.like { //"../../../../assets/helper/icon_like.png"
            background: url("../../../assets/helper/icon_like.png") no-repeat 50%;
            background-size: cover;
        }
        a.no-like {
            background: url("../../../assets/helper/icon_nolike.png") no-repeat 50%;
            background-size: cover;
        }
        a.like.cur {
            background: url("../../../assets/helper/icon_nolike_cur.png") no-repeat 50%;
            background-size: cover;
        }
        a.no-like.cur {
            background: url("../../../assets/helper/icon_nolike_cur.png") no-repeat 50%;
            background-size: cover;
        }
    }
}

.robot {
    padding: 0 0;
    background-color: #ffffff;

    .robot-box {
        background-color: #fafafa;
        .robot-chat {
            height: calc(100vh - 148px);
            overflow: auto;
            scroll-behavior: smooth;

            .chat-list {
                overflow: auto;
                padding: 0 30px;
                position: relative;
                text-align: center;
            }
        }

        .srarch_main {
            position: absolute;
            width: 100%;
            bottom: 60px;
            z-index: 10;
            background-color: #fff;
            .list {
                li {
                    display: list-item;
                    line-height: 35px;
                    padding: 0 20px;
                    border-bottom: 1px solid #eee;
                    font-size: 16px;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                }
            }
        }

        .robot-btn {
            display: flex;
            width: 100%;
            .btn {
                .borderRadius(20px);
                background-color: #fff;
                .sc(13px, #666);
                padding: 5px 20px;
                margin-left: 5px;
                border: 1px solid #efefef;
            }
        }

        .robot-control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            height: 60px;
            padding: 0 10px;
            background-color: #fff;
            border-top: 1px solid #efefef;
            margin-top: 5px;
            .nav-list-item {
                display: flex;
                flex-direction: column;
                color: #666;
                .icon {
                    font-size: 25px;
                }
                span {
                    font-size: 12px;
                }
            }

            input {
                padding: 0;
                border: 0;
                outline: none;
                -webkit-appearance: none;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            
            .control-input{
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                padding: 0 10px;
                font-size: 16px;
                background-color: #eee;
                border-radius: 5px;
                margin-right: 10px;
            }
            
            .control-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                    display: inline-block;
                    width: 80px;
                    height: 40px;
                    background-color: #6e87ea;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 20px;
                    color: #fff;
                }
            }
        }
    }
}

// 输入框
.reply-edit {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    overflow-y: scroll;
    z-index: 10;

    .container {
        .body {
            width: 100%;
            height: 37.333vw;
            background-color: #fff;
            border-bottom: 1px solid #efefef;
            box-sizing: border-box;
            padding: 4vw;
            .reply-textarea {
                width: 100%;
                height: 100%;
                outline: 0;
                resize: none;
                border: none;
                font-size: 3.733vw;
            }
        }

        .bottom {
            padding: 3.333vw 4vw;
            box-sizing: border-box;
            border-bottom: 1px solid #efefef;
            background-color: #fff;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .reply-send {
                flex: 1;
                text-align: right;
                .submit {
                    display: inline-block;
                    width: 18.133vw;
                    height: 6.667vw;
                    border: none;
                    background-color: #54c9ff;
                    border-radius: 6.667vw;
                    text-align: center;
                    font-size: 3.2vw;
                    color: #fff;
                    vertical-align: middle;
                    position: relative;
                    cursor: pointer;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: .4vw;
                }
            }
        }
    }

    @-webkit-keyframes rotate {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0)
        }

        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }

    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0)
        }

        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }
}

</style>